import React from "react"
import { Link } from "gatsby"
import basePageStyles from "../styles/components/basepage.module.scss"

export default function BasePage() {
  return (
    <basepage className={`${basePageStyles.basepage}`}>
    <div>
      <h3>
        welcome to my blog! i made this very late at night and it is not very good. enjoy.
      </h3>
      <h1>
        <Link
          to='cs'
          activeClassName={basePageStyles.navItemActive}
        >
        cs stuff
        </Link>
      </h1>
      <h1>
        <Link
          to='non-cs'
          activeClassName={basePageStyles.navItemActive}
        >
        not cs stuff
        </Link>
      </h1>
    </div>
    </basepage>
  )
}